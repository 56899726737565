<template>
  <div class="dp-f h-22 ai-c">
    <el-checkbox v-model="state.isread"  @click="readClick" />
    <div v-if="configu.mode != 2">
      <div class="read ml-8" :class="state.jitter ? 'readac' : ''">
        请仔细阅读并同意<span :class="['cu-p', state.readIs2 ? 'readIsAc' : '']" @click="read(2, '电子认证服务协议')">《电子认证服务协议》</span>,<span
          :class="['cu-p', state.readIs3 ? 'readIsAc' : '']" @click="read(3, '个人信息保护政策')">《个人信息保护政策》</span>和<span
          :class="['cu-p', state.readIs4 ? 'readIsAc' : '']" @click="read(4, '风险告知书')">《风险告知书》</span>
          <!-- 和<span :class="['cu-p', state.readIs5 ? 'readIsAc' : '']" @click="read(5, '数字证书申请表')">《数字证书申请表》</span> -->
      </div>
    </div>
    <div v-else>
      <div class="read ml-8" :class="state.jitter ? 'readac' : ''">
        请仔细阅读并同意<span :class="['cu-p', state.readIs2 ? 'readIsAc' : '']" @click="read(6, '数字证书服务协议')">《数字证书服务协议》</span>
          <!-- 和<span :class="['cu-p', state.readIs5 ? 'readIsAc' : '']" @click="read(5, '数字证书申请表')">《数字证书申请表》</span> -->
      </div>
    </div>
  </div>
  <agreement ref="agreementRef" @typeIndexEmit="agreementEmit" />
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import { handleMessage } from "@/utils/server/confirm.js";
import agreement from '@/components/dialog/agreement.vue'
import {configu} from '@/utils/config.js'
const agreementRef = ref()
const state = reactive({
  jitter: false,//抖动
  isread: false,//协议单选框绑定值
  readprivacy: false,//是否以点击隐私协议
  readIs2: false,//是否已阅读1
  readIs3: false,//是否已阅读2
  readIs4: false,//是否已阅读3
  // readIs5: false,//是否已阅读4
  readIs6: false,//是否已阅读6
})
const agreementEmit = (value) => {
  console.log('agreementEmit', value)
  if (configu.mode == 2 ) {
    state.readIs1 = true
    state.readIs2 = true
    state.readIs3 = true
    state.readIs4 = true
    if (value == 6) {
      state.readIs6 = true
    }

  } else {
    state.readIs6 = true
    if (value == 2) {
      state.readIs2 = true
    } else if (value == 3) {
      state.readIs3 = true
    } else if (value == 4) {
      state.readIs4 = true
    } else if (value == 5) {
      state.readIs5 = true
    }
  }
  if (state.readIs2 == true && state.readIs3 == true && state.readIs4 == true && state.readIs6 == true) {
    state.readprivacy = true
  }
}
const readClick = () => {
  // if (state.readprivacy == false) {
  //   handleMessage('请先阅读协议')
  // }
}
const read = (type, title) => {
  unref(agreementRef).show(type, title)
}
// 抖动方法
const jitter = () => {
  if (state.isread == true) return
  state.jitter = true
  setTimeout(() => {
    state.jitter = false
  }, 800);
}
defineExpose({
  state, jitter
});
</script>
<style lang="scss" scoped >
@keyframes shake {

  /* 水平抖动，核心代码 */
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(+2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(+4px, 0, 0);
  }

  50% {
    transform: translate3d(-4px, 0, 0);
  }
}

.read {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: rgba(36, 137, 242, 1);
  }
}

.readIsAc {
  color: red !important;
}

.readac {
  animation: shake 800ms ease-in-out;
}

::v-deep .el-checkbox__inner {
  border-radius: 50%;
}</style>