<template>
    <div class="navigationbox w100 h-80 dp-f ai-c jc-c">
        <div class="return dp-f cu-p pt-a" @click="back()">
            <img class="w-10 h-16 mt-4 mr-6" src="@/assets/img/general/left.png">
            <div>返回上一页</div>
        </div>
        <div>{{props.title}}</div>
    </div>
</template>
<script setup>
import {router_push_name,router_back} from "@/utils/server/router";
import { useRoute } from "vue-router";
import { reactive } from "vue";
const props = defineProps({
  title: {
    //标题名
    type: String,
    default:()=>{
        return '个人实名认证'
    }
  },
});
const route=useRoute()
const state = reactive({

});
const back=()=>{
    router_push_name('user',undefined,false)
    // if(route.name=='realname_boot'){
    //     router_push_name('user',undefined,false)
    // }else{
    //     router_back(undefined,false)
    // }
}
</script>
<style lang="scss" scoped >
.navigationbox{
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.06);
    .return{
        left: 34px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292728;
    }
}
</style>