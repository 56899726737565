<template>
  <div class="dp-f h-22 ai-c">
    <el-checkbox v-model="state.isread" :disabled="!state.readprivacy" @click="readClick"/>
    <div class="read ml-8" :class="state.jitter ? 'readac' : ''">
      请仔细阅读并同意<span class="cu-p" @click="read">《隐私保护协议》</span>
    </div>
  </div>
  <agreement ref="agreementRef" @Emit="emit"/>
</template>
<script setup>
import { reactive, ref,unref } from 'vue'
import { handleMessage } from "@/utils/server/confirm.js";
import agreement from '@/components/dialog/agreement.vue'
const agreementRef=ref()
const state = reactive({
  jitter: false,//抖动
  isread: false,//协议单选框绑定值
  readprivacy:false,//是否以点击隐私协议
})
const readClick=()=>{
  if(state.readprivacy==false){
    handleMessage('请先阅读隐私保护协议')
  }
}
const read = () => {
  unref(agreementRef).show(0,'隐私协议')
  // window.open("http://116.63.252.12:8099/#/file?url=api/group1/M00/00/8D/wKgAXmPslYWAUDwxAABtiBGg3mE17.docx&title="+encodeURI('隐私政策'))//隐私协议
}
const emit = () => {
  state.readprivacy = true
  state.isread = true
}
// 抖动方法
const jitter = () => {
  if (state.isread == true) return
  state.jitter = true
  setTimeout(() => {
    state.jitter = false
  }, 800);
}
defineExpose({
  state, jitter, read
});
</script>
<style lang="scss" scoped >
@keyframes shake {

  /* 水平抖动，核心代码 */
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(+2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(+4px, 0, 0);
  }

  50% {
    transform: translate3d(-4px, 0, 0);
  }
}

.read {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: rgba(36, 137, 242, 1);
  }
}

.readac {
  animation: shake 800ms ease-in-out;
}

::v-deep .el-checkbox__inner {
  border-radius: 50%;
}
</style>