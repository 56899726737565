<template>
  <div class="pt-r box">
    <div class="bgbox pt-a"></div>
    <navigation :title="state.title" class="ritem"></navigation>
    <div class="w100 pt-40 pr-30 pl-30 ritem">
      <div class="dp-f jc-sb mb-40">
        <div class="dp-f h-32 ai-c">
          <img class="w-24 h-24 mr-20" src="@/assets/img/general/certification.png">
          <div class="fs-18 mr-18">{{ state.title }}</div>
          <realname></realname>
          <!-- <realname is="true" :CSStype="2"></realname> -->
        </div>
        <div class="dp-f">
          <oabutton width='76' height='32' title="认证记录" CSStype=3 style="border-radius: 4px;"
            @buttonclick="router_push_name('realname_record')" v-if="store.state.realName.companyId"></oabutton>
          <oabutton class="ml-10" width='76' height='32' title="继续认证" CSStype=2 style="border-radius: 4px;"
            @buttonclick="showDetail()" v-if="store.state.realName.companyInfo"></oabutton>
          <oabutton v-if="!store.state.realName.companyInfo" class="ml-10" width='76' height='32' title="去认证" CSStype=2
            style="border-radius: 4px;" @buttonclick="certification"></oabutton>
        </div>
      </div>
      <div class="h-22 dp-f ai-c mb-22">
        <img class="w-24 h-24 mr-5" src="@/assets/img/realname/alarm.png">
        <div class="fs-16">{{ state.label }}</div>
      </div>
      <div class="textbox mb-28">
        <div v-for="(el, ind) in state.texts" :key="ind" class="textitem dp-f ai-c" :style="{ marginBottom: el.mb + 'px' }">
          <div class="icon fs-0" :class="el.icon ? 'iconac' : ''"></div>
          <div>
            <span class="boldtext">{{ el.boldtext }}</span>
            {{ el.text }}
          </div>
        </div>
      </div>
      <div v-if="store.state.realName.type == 1">
        <read2 ref="readRef"/>
      </div>
      <div v-else>
        <read ref="readRef"/>
        <read3 ref="read3Ref" />
      </div>
    </div>
    <Detailsdrawer ref="detailsRef"></Detailsdrawer>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from "vue";
import navigation from "./components/navigation.vue";//导航条
import realname from "@/components/icon/realname.vue";//认证标签
import Detailsdrawer from "./components/record/details.vue"//详情
import read from "./components/read.vue"
import read2 from "./components/individual/information/read.vue"
import read3 from "./components/enterprise/information/read.vue"
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { handleMessage } from "@/utils/server/confirm.js";
import { router_push_name } from "@/utils/server/router";
import { httpToken } from "@/utils/request";
import { getrecordId } from "@/utils/server/getdata.js"
import qs from "qs";
const detailsRef = ref();//详情弹框ref
const readRef = ref()//阅读协议ref
const read3Ref = ref()
const route = useRoute()
const store = useStore();
const state = reactive({
  title: '',//标题
  label: '',//副标题
  routeName: '',//跳转的地址
  texts: [],//正文
});
onMounted(() => {
  console.log("realName.type: ", store.state.realName.type)
  if (store.state.realName.type == "1") {  //个人
    unref(readRef).setAutoPop(true)
    unref(readRef).read(2, '电子认证服务协议')
  } else {
    unref(readRef).read()
  }
})
// 展示详情数据
const showDetail = (row) => {
  unref(detailsRef).getDetails(row)
}
const getData = () => {
  if (store.state.realName.type == '1') {
    state.title = '个人实名认证'
    state.label = '您正在进行个人认证，认证过程中将收集您的如下信息：'
    state.texts = [
      { mb: 30, boldtext: '', text: '您的姓名、证件号', icon: true },
      // { mb: 30, boldtext: '', text: '人脸图像或视频（仅人脸识别时获取）', icon: true },
      { mb: 30, boldtext: '', text: '本人开户的手机号（仅手机号认证获取）', icon: true },
      { mb: 0, boldtext: '', text: '本人开户的银行卡号和预留手机号（仅银行卡认证获取）', icon: true },
    ]
    state.routeName = 'realname_individual'
  } else if (store.state.realName.type == '2') {
    state.title = '企业实名认证'
    state.label = '申请企业实名认证需要准备以下材料：'
    state.texts = [
      { mb: 30, boldtext: '资质证明文件：', text: '不同类型组织需要相关部门颁发的资质证明文件的电子照片或加盖公章的复印件（如：企业/个体户需要提供工商营业执照）', icon: true },
      { mb: 30, boldtext: '如果您是企业法定代表人本人，', text: '您可以直接代表企业进行认证（仅支持企业/个体户类型）', icon: true },
      { mb: 30, boldtext: '如果您不是企业法定代表人本人，', text: '那您作为企业认证的经办人，需要提供经企业加盖公章的《认证授权公函》，并通过法定代表人身份认证或对公账户打款认证的方式，对企业进行认证', icon: true },
      { mb: 0, boldtext: '如果您选择对公账户核验，', text: '那您的企业账户会收到银联商务股份有限公司的一笔转账，您需要再规定的时间内提供转账金额和交易摘要的6位随机数字', icon: true },
    ]
    state.routeName = 'realname_enterprise'
  } else {
    router_push_name('user')
  }
}
getData()
getrecordId()
// 认证方法
const certification = () => {
  // 是否阅读协议
  if (unref(readRef).state.isread != true) { 
    unref(readRef).jitter()
    if (store.state.realName.type == '1') {
      handleMessage('请先阅读并同意《电子认证服务协议》和《个人信息保护政策》')
    }else{
      handleMessage('请先阅读并同意《隐私保护协议》')
    }
    return
  }
  if (store.state.realName.type == '2' && unref(read3Ref).state.isread != true) {
      unref(read3Ref).jitter()
    handleMessage('请先阅读《电子认证服务协议》等协议!')
    return
  }
  router_push_name(state.routeName, undefined, false)
}
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

.box {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.bgbox {
  width: 100%;
  height: 715px;
  background: linear-gradient(180deg, rgba(238, 199, 116, 0.09) 0%, rgba(238, 199, 116, 0) 100%);
  z-index: 1;
}

.textbox {
  width: 100%;
  background: rgba(238, 199, 116, 0.1);
  padding: 30px 40px;
  font-weight: 400;
  font-size: 14px;

  .boldtext {
    font-weight: 600;
  }

  .icon {
    width: 5px;
    height: 5px;
    margin-right: 10px;
  }

  .iconac {
    background: #C1C6CD;
    border-radius: 50%;
  }
}

.ritem {
  position: relative;
  z-index: 2;
}

// 单选框样式修</style>